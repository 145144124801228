import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #000000;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @font-face {
    font-family: "Belinda";
    src: local("belinda"), url(Belinda.otf) format("otf");
  }
`;

export const BodyRow = styled.div`
  margin-top: 30px;
`;
export const Logo = styled.div`
  .row {
    align-items: center;
    margin-top: 50px;
  }
  @media screen and (max-width: 1025px) {
    .logo {
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    .logo img {
      width: 100%;
    }
  }
`;
export const MintMobileImg = styled.div`
  padding: 0px !important;

  img {
    width: 100%;
  }
`;
export const MintConnect = styled.div`
  text-align: center;
  button {
    background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
    border: none;
    padding: 15px 40px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
`;
export const Amount = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  span.amount_text {
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--secondary-text);
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
`;
export const MintBtn = styled.div`
  .mint_logo {
    text-align: right;
    margin-right: -12px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      margin-bottom: 55px;
    }

    img{
      @media screen and (max-width: 600px) {
        width: 75%;
      }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1026px) {
    .mint_logo > img {
      width: 74%;
    }

`;
export const CountText = styled.div`
  background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  font-family: "Inter",sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}
`;
export const StyledButton = styled.button`
  padding: 20px;
  font-size: 20px;
  border-radius: 29px;
  border: 5;
  border-color: var(--primary);
  background-color: transparent;
  color: var(--secondary-text);
  width: 100%;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  border-radius: 50px;
  border: none;
  background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
  padding: 15px;
  font-weight: 100;
  font-size: 32px;
  color: #000;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  > span.minus {
    display: block;
    margin-bottom: 5px;
    font-size: 30px;
    font-weight: 900;
  }
  > span.plus {
    display: block;
    font-size: 30px;
    font-weight: 900;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  fontstyle: "normal";
  fontweight: "600";
  font-size: 25px;
  line-height: 36px;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  padding-top: 30px;
`;

export const TextTitle = styled.p`
  background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  font-family: "Inter",sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--secondary-text);
  font-size: 16px;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
  background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const TextDescriptionMintAmount = styled.p`
  padding: 2px 15px;

  .mintamount {
    background: -webkit-linear-gradient(0deg, #ac832b, #edcf3a, #ac832b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
`;

export const HeaderText1 = styled.p`
  font-size: 60px;
  color: #ffffff;
  text-align: left;
  align-self: flex-start;
`;

export const HeaderText2 = styled.p`
  font-size: 60px;
  background: -webkit-linear-gradient(#00e8ff, #ebef34);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  align-self: flex-start;
`;
export const HeaderPara = styled.p`
  .para1 {
    font-size: 18px;
    color: #868686;
    font-family: "Lato";
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 33px;
  }
  .para1 img {
    margin-left: 10px;
    margin-right: 7px;
  }
  .para1 span {
    font-family: "Lato";
    font-size: 26px;
    font-weight: 900;
  }
  .para2 {
    font-size: 18px;
    color: #868686;
    font-family: "Lato";
    line-height: 32px;
    margin-bottom: 20px;
  }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
export const TheMen = styled.div`
  .the_men_img > img {
    width: 100%;
  }
`;
