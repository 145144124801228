import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import {
  BodyRow,
  Logo,
  CountText,
  Amount,
  MintBtn,
  Screen,
  StyledLink,
  Container,
  TextTitle,
  TextDescription,
  TextDescriptionMintAmount,
  MintConnect,
  StyledRoundButton,
  ResponsiveWrapper,
  MintMobileImg,
} from "./styles/globalStyles";
import { SpacerMedium, SpacerSmall } from "./components/Spacer";
import TotalAmount from "./components/TotalAmount";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState();
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });
  const claimNFTs = () => {
    const cost = CONFIG.WEI_COST;
    const gasLimit = CONFIG.GAS_LIMIT;
    const totalCostWei = String(cost * mintAmount);
    const totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      }) 
      .once("error", (err) => {
        console.log("error in blockchain.smartCntract.methods", err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 21) {
      newMintAmount = 21;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <Screen>
      <div class="container-fluid">
        <BodyRow>
          <div class="row">
            <div class="col-md-12 col-xl-4">
              <Logo>
                <div class="logo">
                  <img src="./logo.svg" alt="" />
                </div>
              </Logo>
            </div>
            <div class="col-md-12 col-xl-4">
              <Container flex={1} ai={"center"}>
                {/* <Details /> */}
                <SpacerSmall />

                <ResponsiveWrapper flex={1} style={{ width: "100%" }} test>
                  <Container
                    flex={2}
                    jc={"center"}
                    ai={"center"}
                    style={{
                      background: "transparent",
                      paddingBottom: 45,
                      borderRadius: 40,
                      boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                    }}
                  >
                    <TextTitle
                      style={{
                        textAlign: "center",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "65px",
                        lineHeight: "64px",
                        color: "var(--secondary-text)",
                      }}
                    >

                      {/* <CountText className="count_text">
                        {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                      </CountText> */}
                    </TextTitle>
                    <SpacerSmall />

                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                      <>
                        <TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--secondary)",
                          }}
                        >
                          The sale has ended.
                        </TextTitle>
                        <TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          You can still find {CONFIG.NFT_NAME} on
                        </TextDescription>
                        <SpacerSmall />
                        <StyledLink
                          target={"_blank"}
                          href={CONFIG.MARKETPLACE_LINK}
                        >
                          {CONFIG.MARKETPLACE}
                        </StyledLink>
                      </>
                    ) : (
                      <>
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                          <Container ai={"center"} jc={"center"}>
                            {blockchain.errorMsg !== "" ? (
                              <>
                                <SpacerSmall />
                                <TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--secondary-text)",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "25px",
                                    lineHeight: "36px",
                                  }}
                                >
                                  {blockchain.errorMsg}
                                </TextDescription>
                              </>
                            ) : null}
                          </Container>
                        ) : (
                          <>

                             <Amount>
                              <span className="amount_text">Amount: </span>
                              &nbsp;
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                <span className="minus">-</span>
                              </StyledRoundButton>
                              <SpacerMedium />
                              <TextDescriptionMintAmount>
                                <span className="mintamount">
                                  {" "}
                                  {mintAmount}
                                </span>
                              </TextDescriptionMintAmount>
                              <SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                <span className="plus">+</span>
                              </StyledRoundButton>
                            </Amount>

                            <TotalAmount mintAmount={mintAmount} />
                            <SpacerSmall />
                            {feedback && (
                              <TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--secondary-text)",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "25px",
                                  lineHeight: "36px",
                                }}
                              >
                                {feedback}
                              </TextDescription>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </ResponsiveWrapper>
              </Container>
            </div>
            <div className="col-md-12 col-xl-4">
              {blockchain && blockchain.account ?  null : <MintConnect>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  Connect Wallet
                </button>
              </MintConnect>}
              <MintBtn
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  if (blockchain.account) {
                    e.preventDefault();
                    claimNFTs();
                    getData();
                  }
                }}
              >
                <div className="mint_logo">
                  <img src="./mint_btn.png" alt="" />
                </div>
              </MintBtn>
            </div>
          </div>
          <div className="row">
            <MintMobileImg>
              <div className="col-12">
                <img src="./mobile_mint.png" alt="" />
              </div>
            </MintMobileImg>
          </div>
        </BodyRow>
      </div>
    </Screen>
  );
}

export default App;
