import React from "react";
import { TextTitle } from "../styles/globalStyles";

const TotalAmount = (props) => {
  return (
    <TextTitle style={{ textAlign: "center", color: "var(--secondary-text)" }}>
      <span>
        {`Total `} : {props.mintAmount * 0.963} ETH
      </span>
    </TextTitle>
  );
};

export default TotalAmount;
